import { ReactComponent as ExternalLinkIcon } from "../../assets/images/external-link-icon.svg";

export const TextLink = ({ hostname }) => {
  return (
    <a
      href={`http://${hostname}`}
      target="_blank"
      rel="noopener noreferrer"
      className="hostname-link "
      style={{
        textDecoration: "none",
        maxWidth: "300px",
        textWrap: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
      }}
    >
      {hostname} <ExternalLinkIcon className="ms-1" />
    </a>
  );
};

export const HostnameLink = ({ hostname, isNotDomain, onClick }) => (
  <a
    href={isNotDomain ? "javascript:void(0)" : hostname}
    target={isNotDomain ? undefined : "_blank"}
    rel={isNotDomain ? undefined : "noopener noreferrer"}
    className="hostname-link"
    style={{
      textDecoration: "underline",
    }}
    onClick={(event) => {
      if (isNotDomain) {
        event.preventDefault();
        if (onClick) {
          onClick();
        }
      }
    }}
  >
    <span className={isNotDomain ? "asset-link" : ""}>{hostname}</span>{" "}
    {!isNotDomain && <ExternalLinkIcon className="ms-1" />}
  </a>
);
