import React, { createContext, useContext, useState } from "react";

export const AssetsModalContext = createContext();

export function UseAssetsModal() {
  return useContext(AssetsModalContext);
}

export const AssetsModalContextProvider = ({ children }) => {
  const [modalState, setModalState] = useState({ isOpen: false, value: null });

  const openModal = (value) => setModalState({ isOpen: true, value });

  const closeModal = () => setModalState({ isOpen: false, value: null });

  return (
    <AssetsModalContext.Provider
      value={{
        modalState,
        openModal,
        closeModal,
      }}
    >
      {children}
    </AssetsModalContext.Provider>
  );
};
