import React, { useState } from "react";
import {
  Badge,
  Button,
  Col,
  Image,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import core from "../../util/core";

import Moment from "react-moment";
import { ReactComponent as IconBellMinus } from "../../assets/images/icon-bell-minus.svg";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "../../util/axios";
import { useConfirmationModalContext } from "../../components/ModalConfirmationContext";
import { ReactComponent as IconWarning } from "../../assets/images/icon-warning.svg";
import { ReactComponent as IconCheckCircle } from "../../assets/images/icon-check-circle.svg";
import { ReactComponent as IconLoader } from "../../assets/images/icon-loader.svg";
import { showToast } from "../../util/toasts";
import  { formatDate } from "../../util/formatDate";

const EasmSingle = ({ item, onRemoveItem }) => {
  const [loading, setLoading] = useState(false);
  let modalContext = useConfirmationModalContext();

  const removeThisAlert = async (alert) => {
    let title = "Are you sure you want to delete this alert? ";
    let msg =
      "This action cannot be undone. Confirm to proceed or Cancel to keep the alert.";
    let icon = "alert";

    const result = await modalContext.showConfirmation(
      title,
      <div className="p-3">
        <p>{msg}</p>
      </div>,
      icon,
      "Delete alert"
    );
    if (result) {
      removeAlert(alert);
    }
  };
  const removeAlert = (alert) => {
    const id = alert.id;
    setLoading(true);
    axios
      .delete("/alerts", { params: { id } })
      .then((response) => {
        const data = response.data;
        setLoading(false);
        if (data.msg) {
          onRemoveItem(id);

          showToast(data.msg, "success", "top-center", "light", {
            autoClose: 5000,
          });
        }
      })
      .catch((e) => {
        setLoading(false);
        if (e.response?.status === 422) {
          for (const fieldKey in e.response.data) {
            const field = e.response.data[fieldKey];

            showToast(field.msg, "error", "top-center", "light");

            // helpers.setFieldError(field.path, field.msg);
          }
        }
      });
  };
  const renderServices = () => {
    if (item?.services) {
      let items = item.services.map((service) => {
        if (service?.service.title) return service?.service?.title;
      });
      if (items.length > 0)
        return (
          <div className="m-2">
            {" "}
            Services {": "}
            <small className="ms-1 text-muted">{items.join(", ")}</small>
          </div>
        );
    }
    return null;
  };
  const processStatus = (status) => {
    let txt = "Error";
    if (0 === status) txt = "Pending";
    if (1 === status) txt = "Processing";
    if (2 === status) txt = "Processed";
    return txt;
  };
  return (
    <Row className=" rounded border m-0 my-2  shadow-sm">
      <Col className=" p-3">
        <Link
          to={{
            pathname: `/easm/${item.id}`,
            // replace: true,
          }}
          className="nav-link"
        >
          <div className="d-flex m-2">
            <span className="text-muted">
              <img
                alt=""
                src={`/images/icon-mini-alerts.png`}
                height={18}
                className="me-2"
              />{" "}
              {item.scan_name}
            </span>
          </div>

          <div>
            <div className="m-2">
              <Badge bg="secondary">{item.scan_input}</Badge>
            </div>

            {renderServices()}

            <div className="m-2">
              {0 == item.status || 1 == item.status ? (
                <IconLoader className="align-middle  me-2" width="24" />
              ) : 2 === item.status ? (
                <IconCheckCircle className="align-middle me-2" width="24" />
              ) : (
                <IconWarning className="align-middle me-2" width="24" />
              )}
              {item.start_time != null && (
                <>
                  <small className="ms-1 text-muted">
                    {processStatus(item.status)}
                  </small>
                  <i className="bi bi-clock mx-2" title="Date & Time"></i>
                  Start Time {": "}
                  <small className="ms-1 text-muted">
                    {formatDate(item.start_time,"MMM DD, YYYY hh:mm")}
                  </small>
                </>
              )}
              {item.end_time != null && (
                <>
                  <i className="bi bi-clock mx-2" title="Date & Time"></i>End
                  Time {": "}
                  <small className="ms-1 text-muted">
                  {formatDate(item.end_time,"MMM DD, YYYY hh:mm")}
                  </small>
                </>
              )}
            </div>
          </div>
        </Link>
      </Col>
      {/* <Col xs="auto" className="p-3 px-1">
        <OverlayTrigger
          overlay={
            <Tooltip id={"alert-" + item.id}>
              {loading ? "Removing the alert" : "Remove this alert"}
            </Tooltip>
          }
          placement="left"
          delayShow={300}
          delayHide={150}
        >
          <Button
            className={loading ? "removing-state" : ""}
            variant="link"
            onClick={(e) => {
              removeThisAlert(item);
            }}
          >
            <i className="bi bi-chevron-right text-white "></i>
          </Button>
        </OverlayTrigger>
      </Col> */}
    </Row>
  );
};

export default EasmSingle;
