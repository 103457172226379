import { services } from "../util/scans";
import axios from "../util/axios";
import React, { createContext, useContext, useRef, useState } from "react";

export const ServicesContext = createContext();

export function UseServices() {
  return useContext(ServicesContext);
}

export const ServicesContextProvider = ({ children }) => {
  const [allServices, setAllServices] = useState([]);
  const timeoutRef = useRef();
  const fetchAllServicesItems = async (id) => {
    if (!id) return;
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    try {
      const { data } = await axios.get(`scans/${id}/services`);
      const updatedServices = services
        .map((service) => {
          const foundService = data?.services?.find(
            (item) => item.service_id === service.id
          );
          if (foundService) {
            let menuEnabled =
              typeof foundService["menu_enabled"] == "undefined"
                ? service.menu_enabled
                : foundService?.menu_enabled;
            let tmpService = {
              ...service,
              status: foundService
                ? foundService.service_status
                : service.status,
              updated_at: foundService?.updated_at,
              created_at: foundService?.created_at,
              has_issues: foundService?.has_issues,
              menu_enabled: menuEnabled,
            };

            return tmpService;
          } else {
            return {
              ...service,
              menu_enabled: 0,
              has_issues: null,
            };
          }
        })
        .filter(function (item) {
          return (item?.status === 3 && item.id === 15) || item.id !== 15;
        });
      setAllServices(updatedServices);

      const allServicesUpdated = updatedServices.every(
        (service) =>
          service.status !== 0 && service.status !== 1 && service.status !== 2
      );
      if (!allServicesUpdated || data?.services?.length === 0) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => fetchAllServicesItems(id), 5000);
      }
    } catch (err) {
      if (err?.response?.data?.status === false) {
        setAllServices(services);
        fetchAllServicesItems(id);
      }
    }
  };

  return (
    <ServicesContext.Provider
      value={{
        allServices,
        fetchAllServicesItems,
      }}
    >
      {children}
    </ServicesContext.Provider>
  );
};
