import React, { useEffect,useContext, useState } from "react";
import { useParams,useLocation, useNavigate } from "react-router-dom";
import GenericCard from "../../components/card/GenericCard";
import ProgressBar from "../../components/form/CustomProgressBar";
import UtilizationBanner from "./UtilizationBanner";
import { ReactComponent as InfoIcon } from "../../assets/images/Info-circle-blue.svg";
import { ReactComponent as WarningIcon } from "../../assets/images/WARNING.svg";
import { ReactComponent as DangerIcon } from "../../assets/images/Xmark-circle.svg";
import axios from "../../util/axios";
import { ScannerContext } from "../../components/ScannerContext";



const MyLicense = () => {
  const navigate = useNavigate();
  const [totalAsset, setTotalAsset] = useState(0);
  const [assetsInUse, setAssetsInUse] = useState(0);
  const { dropdownItems } = useContext(ScannerContext);

  const getLicense = async () => {
    try {
      const res = await axios.get("/licencedata");
      setTotalAsset(res.data.totalLimit);
      setAssetsInUse(res.data.usedLimit);
    } catch (error) {
      console.error("Error fetching license data:", error);
    }
  };
  useEffect(() => {
    getLicense();
  }, []);
  const licenseUtilization =
    totalAsset > 0 ? ((assetsInUse / totalAsset) * 100).toFixed(0) : 0;
  const handleContactClick = () => {
    navigate(`/contact`);
  };
  const handleViewAssets = () => {
    navigate(`/assets-inventory/${dropdownItems[0].target_uuid}?tab=2`);
  };
  const formattedTotalAsset = totalAsset.toLocaleString();
  const formattedAssetsInUse = assetsInUse.toLocaleString();
  return (
   
      <div>
        <div className="license-container w-100 d-flex justify-content-center">
          <div style={{ width: "856px" }}>
            <div className="flex align-items-center justify-content-center gap-2">
              <div className="licnese-box" style={{ minWidth: "200px" }}>
                <GenericCard
                  children={
                      <div
                        className={`pb-5 me-3 ${
                          totalAsset === 0 ? "license-no-limit" : "license-units"
                        }`}
                      >
                        {totalAsset === 0 ? "No Limit" : formattedTotalAsset}
                      </div>
                  }
                  title={"Total Asset"}
                />
              </div>
              <div className="licnese-box" style={{ minWidth: "200px" }}>
                <GenericCard
                  children={
                      <div className="license-units me-4">
                        {formattedAssetsInUse}
                        <p
                          className="fs-6 pt-4 mb-0 text-decoration-underline lincense-content"
                          onClick={handleViewAssets}
                        >
                          View Assets in Use
                        </p>
                      </div>
                  }
                  title={"Assets in Use"}
                />
              </div>
              <div className="licnese-box">
                <GenericCard
                  children={
                      <div className="license-units">
                        {licenseUtilization}%{" "}
                        <ProgressBar licenseUtilization={licenseUtilization} />
                      </div>
                  }
                  title={"License Utilization"}
                />
              </div>
            </div>
            {licenseUtilization < 90 && (
              <div className="utilization-container mt-4 border-bottom info-banner-bg">
                <UtilizationBanner
                  Icon={<InfoIcon />}
                  SubTitle={"Your license is optimized for current usage"}
                  Title={`You are currently utilizing ${licenseUtilization}% of your licensed Assets`}
                />
              </div>
            )}
            {licenseUtilization >= 90 && licenseUtilization <= 100 && (
              <div className="utilization-container mt-4 border-bottom warning-banner-bg">
                <UtilizationBanner
                  Icon={<WarningIcon />}
                  SubTitle={
                    "Your license is nearing its limit, consider increasing it"
                  }
                  Title={`You are utilizing ${licenseUtilization}% of your licensed Assets`}
                />
              </div>
            )}
            {licenseUtilization > 100 && (
              <div className="utilization-container mt-4 border-bottom error-banner-bg">
                <UtilizationBanner
                  Icon={<DangerIcon />}
                  SubTitle={
                    "To support ongoing asset scanning and operations, increase your license"
                  }
                  Title={`You have exceeded your asset limit`}
                />
              </div>
            )}
            <div className="pt-4">
              <p className="fs-4 fw-600 mb-2 text-white">
                Need to increase your license?
              </p>
              <p className="fs-5 text-white">
                Our support team is here to help,{" "}
                <span
                  className="cursor-pointer fw-bold contatct-text"
                  onClick={handleContactClick}
                >
                  Contact us
                </span>{" "}
                for further details
              </p>
            </div>
          </div>
        </div>
      </div>
  );
};

export default MyLicense;
