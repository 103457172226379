const cleanMe = function (obj) {
  let data = {};
  for (let prop in obj) {
    let value = removeTags(obj[prop].trim());
    if (isEmpty(value) || value < 10) {
      delete obj[prop];
    } else {
      obj[prop] = value;
    }
  }
  return obj;
};

const removeKey = function (obj, key) {
  if (obj[key]) {
    delete obj[key];
  }
  return obj;
};

const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const noSpecialChars = function (str) {
  const noSpecialChars = str.replace(/[^a-zA-Z0-9 ]/g, "");
  return noSpecialChars;
};

const isEmpty = function (e) {
  switch (e) {
    case "":
    case 0:
    case "0":
    case "NULL":
    case null:
    case false:
    case undefined:
      return true;
    default:
      return false;
  }
};

const prepareSourceName = function (name) {
  // let dotLastIndex = file.lastIndexOf('.');
  return name
    .replace(/\.[^/.]+$/, "")
    .replace(/\s+/g, "-")
    .trim();
};

const removeTags = function (str) {
  if (str === null || str === "") return false;
  else str = str.toString();

  // Regular expression to identify HTML tags in
  // the input string. Replacing the identified
  // HTML tag with a null string.
  // .replace(/\r\n/g, '')
  return str
    .trim()
    .replace(/<\/?[^>]+(>|$)/g, "")
    .replace(/(\s+)|(\\r|\\n)/gm, "")
    .trim();
};
const parseJSON = function (str) {
  let temp = str;
  try {
    temp = temp.replace(/'/g, '"');
    temp = temp.replace(/~!~/g, "'");
    temp = JSON.parse(temp);
  } catch (error) {
    temp = str;
  }
  return temp;
};

const extractJSON = function (str) {
  var firstOpen, firstClose, candidate;
  firstOpen = str.indexOf("{", firstOpen + 1);
  do {
    firstClose = str.lastIndexOf("}");
    if (firstClose <= firstOpen) {
      return null;
    }
    do {
      candidate = str.substring(firstOpen, firstClose + 1);
      try {
        var res = JSON.parse(candidate);
        return res;
        // return [res, firstOpen, firstClose + 1];
      } catch (e) {
        console.log("...failed");
      }
      firstClose = str.substr(0, firstClose).lastIndexOf("}");
    } while (firstClose > firstOpen);
    firstOpen = str.indexOf("{", firstOpen + 1);
  } while (firstOpen != -1);
};

const phoneMask = (phone) => {
  return phone.replace(/(\d{3})(\d{2})(\d{4})/, "($1)-$2-$3");
};

const numberFormat = (number) => {
  return number.toLocaleString("en");
};

const nl2br = (str, replaceMode, isXhtml) => {
  var breakTag = isXhtml ? "<br />" : "<br>";
  var replaceStr = replaceMode ? "$1" + breakTag : "$1" + breakTag + "$2";
  return (str + "").replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, replaceStr);
};

const allIndexOf = (str, toSearch) => {
  var indices = [];
  for (
    var pos = str.indexOf(toSearch);
    pos !== -1;
    pos = str.indexOf(toSearch, pos + 1)
  ) {
    indices.push(pos);
  }
  return indices;
};

const isValidIpv4 = (ip) => {
  const ipv4_regex =
    /^(25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}$/;
  const parts = ip.split(".").map(Number);
  // check the ip not above the 255
  if (parts.length !== 4 || parts.some((part) => part > 255 || isNaN(part))) {
    return false;
  }
  return ipv4_regex.test(ip);
};

const extractIps = (hosts, ips) => {
  if (Array.isArray(hosts)) {
    hosts.map((host) => {
      let tmpHost = host.split(":");

      if (isValidIpv4(tmpHost[0])) {
        ips.push(tmpHost[0]);
      }
      if (isValidIpv4(tmpHost[1])) {
        ips.push(tmpHost[1]);
      }
    });
  }
  ips = [...new Set(ips)];
  return ips;
};

const isValidIpv4Range = (range) => {
  const rangeRegex =
    /^(\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})-(\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})$/;
  const match = range.match(rangeRegex);
  if (match) {
    const [_, startIp, endIp] = match;

    if (isValidIpv4(startIp) && isValidIpv4(endIp)) {
      const startParts = startIp.split(".").map(Number);
      const endParts = endIp.split(".").map(Number);
      // Simple numeric comparison assuming both IPs are in the same subnet
      for (let i = 0; i < 4; i++) {
        if (startParts[i] > endParts[i]) return false;
        if (startParts[i] < endParts[i]) return true;
      }
      return true; // start IP and end IP are the same, or start IP is less than end IP
    }
  }
  return false;
};

const validCidrIp = (input) => {
  const ipRangeRegex = /^(\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})\/(\d{1,2})$/;
  const match = input.match(ipRangeRegex);

  if (match) {
    const subnet = parseInt(match[2], 10);
    return subnet >= 0 && subnet <= 32;
  }
  return false;
};

const filterIpsV4 = (ips) => {
  return ips.filter((ip) => isValidIpv4(ip));
};

const isValidUrl = (urlString) => {
  const urlPattern = new RegExp(
    "^(https?:\\/\\/)?" + // validate protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // validate fragment locator
  // check the ip not above 255
  const ipParts = urlString.match(/^(\d+)\.(\d+)\.(\d+)\.(\d+)$/);
  if (ipParts) {
    const parts = ipParts.slice(1).map(Number);
    if (parts.some((part) => part > 255)) {
      return false;
    }
  }

  return !!urlPattern.test(urlString);
};

const isValidEmail = (urlString) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(urlString);
};

const prepareUrl = (url) => {
  return url.indexOf("http://") >= 0 || url.indexOf("https://") >= 0
    ? url
    : `https://${url}`;
};
const isValidHost = (hostString) => {
  const hosts = hostString?.split(",");
  return hosts?.every((host) => {
    const trimmedHost = host.trim();
    if (trimmedHost && trimmedHost !== "") {
      return (
        isValidIpv4(trimmedHost) ||
        isValidUrl(trimmedHost) ||
        isValidIpv4Range(trimmedHost)
      );
    }
  });
};

const transformKeys = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map(transformKeys);
  } else if (typeof obj === "object" && obj !== null) {
    return Object.entries(obj).reduce((acc, [key, value]) => {
      const newKey = key.toLowerCase().replace(/\s+/g, "_");
      acc[newKey] = transformKeys(value);
      return acc;
    }, {});
  }
  return obj;
};

const decodeUsingDOMParser = (input) => {
  const parser = new DOMParser();
  const decodedString = parser.parseFromString(input, "text/html")
    .documentElement.textContent;
  return decodedString;
};

module.exports = {
  capitalize,
  phoneMask,
  cleanMe,
  removeKey,
  isEmpty,
  numberFormat,
  noSpecialChars,
  prepareSourceName,
  removeTags,
  parseJSON,
  nl2br,
  extractJSON,
  allIndexOf,
  isValidUrl,
  isValidEmail,
  prepareUrl,
  isValidIpv4,
  filterIpsV4,
  extractIps,
  isValidHost,
  validCidrIp,
  transformKeys,
  decodeUsingDOMParser,
};
