import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import StatusCard from "../../components/StatusCard";
import axios from "../../util/axios";
import { Link } from "react-router-dom";

const AdminDashboard = () => {
  const [info, setInfo] = useState([]);

  const getAdminInfo = () => {
    axios.get("admin/info").then((res) => {
      setInfo(res.data);
    });
  };

  useEffect(() => {
    getAdminInfo();
  }, []);

  return (
    <React.Fragment>
      <Container fluid className="main_container_style ">
        <Row className="justify-content-start m-0">
          <Col>
            <h1 className="fw-normal mb-5">Admin Dashboard</h1>
          </Col>
        </Row>

        <Row className="justify-content-start m-0 ">
          <Col sm="12" lg="4" xxl="3" className="my-2">
            <StatusCard
              icon="icon-invitation.png"
              type="img"
              count={info?.invitations || 0}
              title="Invitations"
              bg="secondary"
            />
          </Col>
          <Col sm="12" lg="4" xxl="3" className="my-2">
            <Link to="/admin/users" className="nav-link ">
              <StatusCard
                icon="icon-users.png"
                type="img"
                count={info?.users || 0}
                title="Users"
                bg="danger"
              />
            </Link>
          </Col>
          <Col sm="12" lg="4" xxl="3" className="my-2">
            <StatusCard
              icon="icon-share-link.png"
              type="img"
              count={info?.locators || 0}
              title="Locators"
              bg="primary"
            />
          </Col>
          <Col sm="12" lg="4" xxl="3" className="my-2">
            <Link to="/admin/news" className="nav-link ">
              <StatusCard
                icon="icon-news.png"
                type="img"
                count={info?.pendingNews || 0}
                title="Pending News"
                bg="info"
              />
            </Link>
          </Col>
        </Row>
        <div className="d-flex flex-col align-items-center">
          <Row>
            <div className="d-flex justify-content-center align-content-center w-auto h-25">
              <Link
                style={{ minWidth: "200px" }}
                className="mt-3 m-2 text-center btn-primary btn w-100"
                to="/admin/login-pages"
              >
                Login Pages
              </Link>
            </div>
            <div className="d-flex justify-content-center align-content-center w-auto h-25">
              <Link
                style={{ minWidth: "200px" }}
                className="mt-3 m-2 text-center btn-primary btn w-100"
                to="/admin/cyber-services"
              >
                Cyber Services
              </Link>
            </div>
          </Row>
          <Row>
            <div className="d-flex justify-content-center align-content-center w-auto h-25">
              <Link
                style={{ minWidth: "200px" }}
                className="mt-3 m-2 text-center btn-primary btn w-100"
                to="/admin/organizations"
              >
                Organizations
              </Link>
            </div>
            <div className="d-flex justify-content-center align-content-center w-auto h-25">
              <Link
                style={{ minWidth: "200px" }}
                className="mt-3 m-2 text-center btn-primary btn w-100"
                to="/admin/invitations"
              >
                Invitations
              </Link>
            </div>
          </Row>
          <Row>
            <div className="d-flex justify-content-center align-content-center w-auto h-25">
              <Link
                style={{ minWidth: "200px" }}
                className="mt-3 m-2 text-center btn-primary btn w-100"
                to="/admin/whats-new"
              >
                What's New
              </Link>
            </div><div className="d-flex justify-content-center align-content-center w-auto h-25">
              <Link
                style={{ minWidth: "200px" }}
                className="mt-3 m-2 text-center btn-primary btn w-100"
                to="/admin/security-issues"
              >
                Security Issues
              </Link>
            </div>
          </Row>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default AdminDashboard;
