import React, { useState, useRef, useEffect } from "react";
import { ReactComponent as Arrow } from "../../assets/images/port-arrow.svg";
import { ReactComponent as FaCheckCircle } from "../../assets/images/FaCheckCircle.svg";
import { ReactComponent as FaExclamationCircle } from "../../assets/images/inactive-status-red.svg";
import { ThreeCircles } from "react-loader-spinner";
import { ReactComponent as ExternalLinkIcon } from "../../assets/images/external-link-icon.svg";
import { Overlay, Popover } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import BaseTable from "../../components/table/BaseTable";
import { ReactComponent as ChevronDown } from "../../assets/images/chevrondown.svg";
import { ReactComponent as ChevronUp } from "../../assets/images/chevronup.svg";
import HostnamesList from "../scanners-in-actions/network-security/certificates/HostNameList";
import { UseAssetsModal } from "../../hooks/useAssetsModal.js";

const DomainListTable = ({
  domains,
  servicesStatus,
  handleApplyActions,
  onRowSelect,
  accordianEnabled,
  updateTotalDomain
}) => {
  const navigate = useNavigate();
  const { openModal } = UseAssetsModal();
  const routeParams = useParams();

  const [collapsedState, setCollapsedState] = useState({});
  const [viewMoreState, setViewMoreState] = useState({});
  const [hoveredPort, setHoveredPort] = useState(null);
  const [showPopover, setShowPopover] = useState(false);
  const [itemsPerPage] = useState(30);
  const [expandedRows, setExpandedRows] = useState([]);
  const [groupedData, setGroupedData] = useState([]);

  const portRefs = useRef({});
  const tableRefs = useRef({});

  const actions = [{ id: 1, name: "Reject IP", checked: false }];

  const handleApplyAction = (data) => {
    handleApplyActions(data);
  };
  const handleRowSelect = (data) => {
    onRowSelect(data);
  };
  const initializeCollapsedState = (state) => {
    return domains.reduce((acc, _, index) => {
      acc[index] = state;
      return acc;
    }, {});
  };

  useEffect(() => {
    setCollapsedState(initializeCollapsedState(false));
  }, [accordianEnabled, domains]);

  const toggleCollapse = (index) => {
    setCollapsedState((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
    setExpandedRows([]);
  };

  const toggleViewMoreBase = (index) => {
    setViewMoreState((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };
  useEffect(() => {
    const grouped = domains.reduce((acc, domain) => {
      if (!acc[domain.domain_id]) {
        acc[domain.domain_id] = {
          domain: domain.domain,
          targetUid: domain?.targetUID,
          ips: [],
          withoutHostIps: [],
        };
      }
      const sortedScanPorts = domain.scan_ports
        ? domain.scan_ports.slice().sort((a, b) => a.ports.port - b.ports.port)
        : [];
      acc[domain.domain_id]?.[
        domain.scan_hosts && domain.scan_hosts.length > 0
          ? "ips"
          : "withoutHostIps"
      ]?.push({
        id: domain.id,
        ip: domain.ip.ip,
        status: domain.status,
        statusValue: domain.statusValue,
        hostingProvider: domain.hostingProvider,
        ports: domain.web_ports ? domain.web_ports.split(",") : [],
        scan_hosts: domain.scan_hosts || [],
        scan_ports: sortedScanPorts,
        cdn_name: domain.cdn_name,
        hosting_provider: domain.hosting_provider,
        is_processed: domain.is_processed,
        number_issues: domain?.number_issues,
        ip_id: domain?.ip_id,
        domain_id: domain?.ip?.domain_id,
      });
      return acc;
    }, {});

    setGroupedData(grouped);
  }, [domains]);

  Object.values(groupedData).forEach((domain) => {
    domain.ips.sort((a, b) => {
      if (a.status && !b.status) return -1;
      if (!a.status && b.status) return 1;

      if (a.status && b.status) {
        if (a.scan_hosts.length > b.scan_hosts.length) return -1;
        if (a.scan_hosts.length < b.scan_hosts.length) return 1;
      }
      return 0;
    });
    domain.withoutHostIps.sort((a, b) => {
      if (a.status && !b.status) return -1;
      if (!a.status && b.status) return 1;
      return 0;
    });
  });

  const columns = (tableIndex) => [
    {
      Header: "Hostname",
      key: "scan_hosts",
      isSortable: true,
      width: 50,
      accessor: (row) => {
        const rowId = row.id;
        const showAll = viewMoreState[rowId];
        const isExpanded = expandedRows[rowId] || false;
        return (
          <div>
            <div className="d-flex">
              <div className="d-flex me-4">
                {isExpanded ? (
                  <>
                    <ChevronDown
                      className="cursor-pointer"
                      onClick={() => {
                        tableRefs.current[tableIndex]?.handleRowExpand(rowId);
                        handleRowExpand(rowId);
                      }}
                    />
                  </>
                ) : (
                  <>
                    <ChevronUp
                      className="cursor-pointer"
                      onClick={() => {
                        tableRefs.current[tableIndex]?.handleRowExpand(rowId);
                        handleRowExpand(rowId);
                      }}
                    />
                  </>
                )}
              </div>
              <div className="d-grid ">
                {row.scan_hosts.length === 0 ? (
                  <div className="hostname-link-blank">-</div>
                ) : (
                  row.scan_hosts
                    .slice(0, showAll ? row.scan_hosts.length : 4)
                    .map((host, i) => (
                      <div
                        className={`${
                          row.scan_hosts.slice(
                            0,
                            showAll ? row.scan_hosts.length : 4
                          ).length !==
                            i + 1 && "mb-2"
                        }`}
                        key={i}
                      >
                        {row?.status ? (
                          <HostnamesList hostname={host.host.host} />
                        ) : (
                          <div
                            className="hostname-inactive asset-link"
                            onClick={() => openModal(host.host.host)}
                            style={{
                              textDecoration: "underline",
                            }}
                          >
                            {host.host.host}{" "}
                          </div>
                        )}
                      </div>
                    ))
                )}

                <div>
                  {row.scan_hosts.length !== 0 && (
                    <div>
                      {row.scan_hosts.length > 4 && (
                        <div className="view-more-main mt-1">
                          <span
                            className="view-more"
                            onClick={() => toggleViewMoreBase(rowId)}
                          >
                            {showAll
                              ? "View Less"
                              : `View All ${row.scan_hosts.length}`}
                          </span>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      Header: "IP Address",
      key: "ip",
      isSortable: true,
      width: 25,
      accessor: (row) => (
        <div onClick={() => openModal(row?.ip)} className="asset-link">
          {row.ip}
        </div>
      ),
    },
    {
      Header: "Status",
      accessor: "ip_status",
      isSortable: true,
      key: "statusValue",
      width: 20,
      accessor: (row) => {
        let statusLabel;
        let statusIcon;
        let statusColor;
        let statusValue = "";
        if (!row?.is_processed) {
          statusValue = "Validating";
        } else {
          if (row?.status) {
            statusValue = "Active";
          } else {
            statusValue = "Inactive";
          }
        }
        switch (statusValue) {
          case "Validating":
            statusLabel = "Validating";
            statusIcon = <ThreeCircles height={18} width={18} color="white" />;
            statusColor = "#fff";
            break;
          case "Active":
            statusLabel = "Active";
            statusIcon = <FaCheckCircle className="animation-status" />;
            statusColor = "#3DDC97";
            break;
          case "Inactive":
            statusLabel = "Inactive";
            statusIcon = <FaExclamationCircle />;
            statusColor = "#FF6155";
            break;
        }

        return (
          <div
            style={{
              color: statusColor,
              display: "flex",
              alignItems: "center",
            }}
          >
            {statusIcon}{" "}
            <span
              className={statusValue === "Active" ? "animation-status" : ""}
              style={{ marginLeft: "5px" }}
            >
              {statusLabel}
            </span>
          </div>
        );
      },
    },
  ];
  const expandedColumns = [
    {
      Header: "Hosting Provider",
      key: "hosting_provider",
      isSortable: true,
      width: 20,
      accessor: (row) => {
        let statusLabel = "Scanning";
        let statusIcon = <ThreeCircles height={18} width={18} color="white" />;
        let statusColor = "#fff";

        return !row.hosting_provider &&
          servicesStatus.find((s) => s.id == 7)?.status < 3 ? (
          <div
            style={{
              color: statusColor,
              display: "flex",
              alignItems: "center",
            }}
          >
            {statusIcon}{" "}
            <span style={{ marginLeft: "5px" }}>{statusLabel}</span>
          </div>
        ) : !row.hosting_provider ? (
          "-"
        ) : (
          row.hosting_provider
        );
      },
    },
    {
      Header: "CDN",
      key: "cdn_name",
      width: 25,
      isSortable: true,
      accessor: (row) => {
        let statusLabel = "Scanning";
        let statusIcon = <ThreeCircles height={18} width={18} color="white" />;
        let statusColor = "#fff";
        let { ips } = Object.values(groupedData)[0];
        let filteredIps = ips.find(
          (i) => i.ip == row.ip && i.scan_hosts.length > 0
        );
        let hostsCdn = "-";
        if (filteredIps) {
          hostsCdn = filteredIps.scan_hosts[0].cdn_name || "-";
        }
        return !row.cdn_name &&
          servicesStatus.find((s) => s.id == 9)?.status < 3 ? (
          <div
            style={{
              color: statusColor,
              display: "flex",
              alignItems: "center",
            }}
          >
            {statusIcon}{" "}
            <span style={{ marginLeft: "5px" }}>{statusLabel}</span>
          </div>
        ) : !row.cdn_name ? (
          hostsCdn
        ) : (
          row.cdn_name
        );
      },
    },
    {
      Header: "Port",
      isSortable: false,
      width: 35,
      accessor: (row) => {
        if (!row.is_processed) {
          let statusLabel = "Scanning";
          let statusIcon = (
            <ThreeCircles height={18} width={18} color="white" />
          );
          let statusColor = "#fff";
          return (
            <div
              style={{
                color: statusColor,
                display: "flex",
                alignItems: "center",
              }}
            >
              {statusIcon}{" "}
              <span style={{ marginLeft: "5px" }}>{statusLabel}</span>
            </div>
          );
        }
        const rowId = row.id;
        const showAllPorts = viewMoreState[`ports_${rowId}`];
        const popover = (
          <Popover
            id={`popover-ports-${rowId}`}
            className="custom-popover-arrow"
          >
            <Popover.Body className="comming-tool-body d-grid scrollable-checkbox-list">
              {row.status == -1 ? (
                <span className="text-black port-box">
                  Port details are masked to prevent identification of network
                  services
                </span>
              ) : (
                <>
                  {row.scan_ports.map((port, i) =>
                    port.service === "http" || port.service === "https" ? (
                      <div key={i}>
                        <a
                          href={`http://${row.ip}:${port.ports.port}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className=""
                        >
                          <div className="d-flex align-items-center">
                            <label className="me-2 text-black port-box">
                              {" "}
                              <span>·</span> {port.ports.port}: {port.service}
                            </label>{" "}
                            <ExternalLinkIcon height={15} width={15} />
                          </div>
                        </a>
                      </div>
                    ) : (
                      <span key={i} className="text-black port-box">
                        <span>·</span> {port.ports.port}: {port.service}{" "}
                      </span>
                    )
                  )}
                  <div
                    className="coming-tool-view"
                    onClick={() => {
                      navigate(
                        `/scanner/${routeParams?.id}/ports?ip=${row?.ip}`
                      );
                    }}
                  >
                    View More Details
                  </div>
                </>
              )}
            </Popover.Body>
          </Popover>
        );

        return (
          <div
            onMouseEnter={() => {
              document.body.style.overflowY = "hidden";
              setHoveredPort(rowId);
              setShowPopover(true);
            }}
            onMouseLeave={() => {
              setHoveredPort(null);
              setShowPopover(false);
            }}
            ref={(el) => (portRefs.current[rowId] = el)}
            className="w-fit-content"
          >
            {row.status != -1 ? (
              row.scan_ports.length > 0 ? (
                <>
                  {row.scan_ports.slice(0, 4).map((port, i) => (
                    <span key={i} className="domain-badge">
                      {port.ports.port}
                    </span>
                  ))}
                  {row.scan_ports.length > 4 && (
                    <span className="domain-badge cursor-pointer">
                      +{row.scan_ports.length - 4}
                    </span>
                  )}
                </>
              ) : (
                row.ports.map((port, i) => (
                  <span key={i} className="domain-badge">
                    {port}
                  </span>
                ))
              )
            ) : (
              "-"
            )}
            <Overlay
              show={hoveredPort === rowId && showPopover}
              target={portRefs.current[rowId]}
              placement="top"
              onMouseEnter={() => setShowPopover(true)}
              onMouseLeave={() => setShowPopover(false)}
            >
              {popover}
            </Overlay>
          </div>
        );
      },
    },

    {
      Header: "Security Issues",
      key: "issues",
      width: 15,
      isSortable: true,
      accessor: (row) => {
        return (
          <div
            onClick={() => {
              SecurityIssueCard(row);
            }}
            className="cursor-pointer underline"
          >
            {row?.number_issues || "-"}
          </div>
        );
      },
    },
  ];

  const SecurityIssueCard = (data) => {
    if (data.number_issues > 0) {
      navigate(`/security-issues/${routeParams?.id}?ip=${data?.ip}`);
    }
  };
  const handleRowExpand = (rowId) => {
    setExpandedRows((prev) => ({
      ...prev,
      [rowId]: !prev[rowId],
    }));
  };
  useEffect(() => {
    const totalTables = Object.keys(groupedData).length;
    
    if (updateTotalDomain) {
        updateTotalDomain(totalTables); 
    }
}, [groupedData, updateTotalDomain]);

  return (
    <>
      {domains.length > 0 ? (
        <div className="">
          {Object.entries(groupedData).map(([domain, data], index) => {
            const totalHostnames = data.ips.reduce((sum, ip) => {
              return sum + ip.scan_hosts.length;
            }, 0);
            const totalIpAlive =
              data.ips.reduce((sum, ip) => {
                return sum + 1;
              }, 0) +
              data.withoutHostIps.reduce((sum, ip) => {
                return sum + 1;
              }, 0);

            const totalActiveIps =
              data.ips.reduce((sum, ip) => {
                if (ip.status === 1 || ip.status === -1) {
                  return sum + 1;
                }
                return sum;
              }, 0) +
              data.withoutHostIps.reduce((sum, ip) => {
                if (ip.status === 1 || ip.status === -1) {
                  return sum + 1;
                }
                return sum;
              }, 0);
            const totalActiveHostnames = data.ips.reduce((sum, ip) => {
              if ((ip.status === 1 || ip.status === -1) && ip?.ip) {
                return sum + ip.scan_hosts.length;
              }
              return sum;
            }, 0);
            return (
              <div key={index} className="domain-container domain-list-table">
                <div
                  className="left-domain-container"
                  onClick={() => toggleCollapse(index)}
                >
                  <Arrow
                    className={
                      collapsedState[index]
                        ? "arrow-rotated-up"
                        : "arrow-rotated-down"
                    }
                  />
                  <span>{data?.domain || "Unknown"}</span>
                  <div className="total-domain-details">
                    <div className="divider" />
                    <div className="detail-text">
                      <span>{totalHostnames}</span> Hostnames ({" "}
                      {totalActiveHostnames} Active) &
                      <span> {totalIpAlive}</span> IPs ( {totalActiveIps}{" "}
                      Active) were found.
                    </div>
                  </div>
                </div>
                <div className="right-domain-container">
                  {collapsedState[index] ? (
                    <div></div>
                  ) : (
                    <div className="domain-collapse-table">
                      <BaseTable
                        ref={(el) => (tableRefs.current[index] = el)}
                        columns={columns(index)}
                        data={data.ips}
                        showCheckboxes={true}
                        selectable={false}
                        expandedColumns={expandedColumns}
                        action={true}
                        actions={actions}
                        isPagination={true}
                        onApplyActions={handleApplyAction}
                        perPageRecords={itemsPerPage}
                        containerClassName=""
                        withoutHostIps={data.withoutHostIps}
                        keyIndex={index}
                        onRowSelect={handleRowSelect}
                      />
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="domain-container domain-list-table no-data">
          No Data Was Found
        </div>
      )}
    </>
  );
};

export default DomainListTable;
