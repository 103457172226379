import React, { useState } from "react";
import { Button, Col, Image, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import core from "../../util/core";

import Moment from "react-moment";
import { ReactComponent as IconBellPlus } from "../../assets/images/icon-bell-plus.svg";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "../../util/axios";
import AddAlert from "./AddAlert";
import  { formatDate } from "../../util/formatDate";


const HistorySingle = ({ item }) => {
  const [loading, setLoading] = useState(false);

  
  return (

    <Row className=" rounded border m-0 my-2  shadow-sm">

      <Col className=" p-3">
        <Link
          to={{
            pathname: "/leaks",
            search: `?keyword=${item.keyword}`,
            // replace: true,
          }}
          className="nav-link"
        >
          <div className="d-flex m-0 p-0">
            <i className="bi bi-search append-icon text-white me-2"></i>{" "}
            <span className="text-muted">{item.keyword}</span>
          </div>

          <div>
            <div className="m-2">
              <i className="bi bi-clock me-2" title="Date & Time"></i>Date &
              Time {": "}
              <small className="ms-1 text-muted">
                {formatDate(item.created_at,"MMM DD, YYYY hh:mm")}
              </small>
            </div>
            <div className="m-2">
              <i className="bi bi-database me-2" title="Results Found"></i>
              Results Found{": "}
              <small className="ms-1 text-muted">
                {(item.es_count * 1 + item.dehashed_count * 1).toLocaleString(
                  "en"
                )}
              </small>
            </div>
          </div>
        </Link>
      </Col>
      <Col xs="auto" className="p-3 px-1">

        {/* <AddAlert keyword={item.keyword} /> */}
        
      </Col>
    </Row>

  );
};

export default HistorySingle;
