import React, { useState } from "react";
import { ReactComponent as Arrow } from "../../assets/images/port-arrow.svg";
import BaseCheckbox from "../../components/form/BaseCheckbox";
import { ReactComponent as CheckIcon } from "../../assets/images/check-white.svg";
import { ReactComponent as CancelIcon } from "../../assets/images/cancel-white.svg";
import moment from "moment";
import  { formatDate } from "../../util/formatDate";

const DomainScanTable = ({
  asset,
  setIsModalOpen,
  setDomainToScan,
  setIsRejectMode,
  setShowDomain,
  selectedSubDomains,
  setSelectedSubDomains,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleCheckboxChange = (domain) => {
    setSelectedSubDomains((prevSelected) => {
      const newSelected = prevSelected.includes(domain.subdomain || domain.ip)
        ? prevSelected.filter((d) => d !== (domain.subdomain || domain.ip))
        : [...prevSelected, domain.subdomain || domain.ip];
      return newSelected;
    });
  };

  const handleSelectAll = () => {
    const allSubDomains = asset.subdomains.map(
      (sub) => sub.subdomain || sub.ip
    );
    if (allSubDomains.every((sub) => selectedSubDomains.includes(sub))) {
      setSelectedSubDomains((prevSelected) =>
        prevSelected.filter((d) => !allSubDomains.includes(d))
      );
    } else {
      setSelectedSubDomains((prevSelected) => [
        ...prevSelected,
        ...allSubDomains.filter((sub) => !prevSelected.includes(sub)),
      ]);
    }
  };

  const handleOpenModalForScan = (event) => {
    event.stopPropagation();
    setDomainToScan(asset.root_domain);
    setIsModalOpen(true);
    setIsRejectMode(false);
    setShowDomain(true);
  };

  const handleOpenModalForDomain = (domain, event) => {
    event.stopPropagation();
    setDomainToScan(domain.subdomain || domain.ip);
    setIsModalOpen(true);
    setIsRejectMode(false);
    setShowDomain(false);
  };

  const handleOpenModalForReject = (domain, event, showDomain) => {
    event.stopPropagation();
    setDomainToScan(domain);
    setIsModalOpen(true);
    setIsRejectMode(true);
    setShowDomain(showDomain);
  };
  const isAllSubDomainsSelected = asset.subdomains.every((sub) =>
    selectedSubDomains.includes(sub.subdomain || sub.ip)
  );
  return (
    <div className="col-12 domain-scan-table p-0 mt-2">
      <div className="domain-asset-header" onClick={toggleCollapse}>
        <div className="domain-table-heading">
          {asset.subdomains?.length > 0 && (
            <Arrow
              className={
                isCollapsed ? "arrow-rotated-right" : "arrow-rotated-down"
              }
            />
          )}
          <span className="domain-name">{asset.root_domain || "Unknown"}</span>{" "}
          <span>|</span>{" "}
          <span className="domain-header-start">
            {asset.root_domain
              ? asset.is_blocked
                ? "The entire domain could not be scanned to reveal connected assets"
                : "Domain not yet scanned to reveal all connected assets"
              : "IP addresses that cannot be resolved to hostnames"}
          </span>
        </div>
        {asset.root_domain && (
          <div className="domain-header-end">
            {!asset.is_blocked && (
              <span className="scan" onClick={handleOpenModalForScan}>
                Scan Domain
              </span>
            )}
            {!asset.is_blocked && <span className="domain-saperator"> | </span>}
            <span
              className="scan"
              onClick={(event) =>
                handleOpenModalForReject(asset.root_domain, event, true)
              }
            >
              Reject domain
            </span>
          </div>
        )}
      </div>
      {asset.subdomains?.length > 0 && (
        <>
          {!isCollapsed && (
            <div className="domain-asset-content row">
              <div className="port-collapse-table col-10">
                <table>
                  <thead>
                    <tr>
                      <th>
                        <BaseCheckbox
                          checked={isAllSubDomainsSelected}
                          onChange={handleSelectAll}
                          name="selectAll"
                        />
                      </th>
                      <th>Hostname</th>
                      <th>IP Address</th>
                      <th>Hosting Provider</th>
                      <th>CDN</th>
                      <th>First Detected</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {asset.subdomains.map((domain) => (
                      <tr key={domain?.id}>
                        <td>
                          <BaseCheckbox
                            checked={selectedSubDomains.includes(
                              domain.subdomain || domain.ip
                            )}
                            onChange={() => handleCheckboxChange(domain)}
                            name={`select-${domain.subdomain || domain.ip}`}
                          />
                        </td>
                        <td>{domain.subdomain || "-"}</td>
                        <td>{domain.ip || "-"}</td>
                        <td>{domain.hosting || "-"}</td>
                        <td>{domain.cdn || "-"}</td>
                        <td>
                          {domain.first_detected
                            ? formatDate(domain.first_detected)
                            : "-"}
                        </td>
                        <td>
                          <div className="action-icons">
                            <span
                              className="action-check"
                              onClick={(event) =>
                                handleOpenModalForDomain(domain, event)
                              }
                            >
                              <CheckIcon height={14} />
                            </span>
                            <span
                              className="action-cancel"
                              onClick={(event) =>
                                handleOpenModalForReject(
                                  domain.subdomain || domain.ip,
                                  event,
                                  false
                                )
                              }
                            >
                              <CancelIcon height={14} />
                            </span>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default DomainScanTable;
