import React, {
  useState,
  useEffect,
  useMemo,
  useContext,
  useRef,
  useCallback,
} from "react";
import BaseTable from "../../components/table/BaseTable.js";
import BaseFilter from "../../components/filter/BaseFilter.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import axios from "../../util/axios.js";
import moment from "moment";
import GenericCard from "../../components/card/GenericCard.js";
import DoughnutChart from "../../components/charts/DoughnutChart.js";
import Data from "../../components/summary/AssetSummary.jsx";
import { ReactComponent as GlobeIcon } from "../../assets/images/globe-blue.svg";
import { ReactComponent as IPAddressIcon } from "../../assets/images/ip.svg";
import { ReactComponent as HostnameIcon } from "../../assets/images/server.svg";
import {
  useNavigate,
  useParams,
  useLocation,
  useOutletContext,
} from "react-router-dom";
import { formatScannerName } from "../../helpers/formatScannerName.js";
import { ScannerContext } from "../../components/ScannerContext.js";
import getFilteredColumns from "../../components/AssetsArchive/FilteredColumnsConfig.js";
import { webUpdatesFilter } from "../../util/genericFunctions.js";
import EmptyAssetsArchive from "./EmptyAssetsArchive.js";
import { assetsArchiveTabsConfig } from "../../util/tabsConfig.js";
import { parseFilterString, applyCondition } from "../../util/conditions";
import { Chart } from "chart.js";
import initialColumns from "./assetsArchiveColumns";
import {
  discoveredByFilterOptions,
  networkZoneFilterOptions,
  criticalityFilterOptions,
  firstDetectedFilterOptions,
} from "./FilterOptions.js";
import  { formatDate } from "../../util/formatDate.js";
const AssetsArchive = ({ data, servicesStatus, assetsStatus, summary }) => {
  const { dropdownItems } = useContext(ScannerContext);
  const navigate = useNavigate();
  const routeParams = useParams();
  const [targetScan, setTargetScan] = useState({});
  const [activeFilters, setActiveFilters] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [allColumnsObj, setAllColumnsObj] = useState([]);
  const colorList = ["#846C6C", "#A7A7B1"];

  const [doughnutChartType, setDoughnutChartType] = useState({
    labels: ["Rejected", "Removed"],
    datasets: [
      {
        data: [100, 20, 30, 30],
        backgroundColor: colorList,
        hoverBackgroundColor: colorList,
        borderColor: "transparent",
        borderWidth: 0,
      },
    ],
  });
  const centerTextPlugin = {
    id: "centerText",
    afterDraw: function (chart) {
      const ctx = chart.ctx;
      const width = chart.width;
      const height = chart.height;
      const totalIssuesStatus =
        chart.config.options.plugins.centerText?.totalIssuesStatus;

      if (totalIssuesStatus !== undefined) {
        ctx.restore();
        const fontSize = (height / 114).toFixed(2);
        ctx.font = `${fontSize}em Kodchasan`;
        ctx.textBaseline = "middle";
        ctx.fillStyle = "#F5F5F5";

        const text = `${totalIssuesStatus}`; // Total value to display
        const textX = Math.round((width - ctx.measureText(text).width) / 2);
        const textY = height / 2;

        ctx.fillText(text, textX, textY);
        ctx.save();
      }
    },
  };

  Chart.register(centerTextPlugin);
  const totalIssuesStatus = doughnutChartType.datasets[0].data.reduce(
    (acc, val) => acc + val,
    0
  );
  const doughnutChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: "70%",
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: "white",
        titleColor: "black",
        bodyColor: "black",
        callbacks: {
          label: function (context) {
            const label = context.dataset.label || "";
            const value = context.raw;
            return `${label}: ${value}`;
          },
        },
        yAlign: "bottom",
        xAlign: "center",
      },
      title: {
        display: false,
      },
      centerText: {
        totalIssuesStatus: totalIssuesStatus,
      },
    },
    elements: {
      arc: {
        borderWidth: 0,
        borderColor: "transparent",
      },
    },
    onHover: (event, elements) => {
      event.native.target.style.cursor = elements.length
        ? "pointer"
        : "default";
    },
  };

  const [tabs, setTabs] = useState([]);

  const updateTabsWithData = (data) => {
    const domainName = Array.from(new Set(data.map((el) => el.domain)))
      ?.filter((name) => name)
      .map((item) => ({
        id: item,
        name: item,
        type: "Domain: " + item,
        key: "1",
        active: false,
      }));
    const categoryList = data?.flatMap(
      (el) =>
        el?.scan_ip_categories?.map(
          (service) => service.service_category.title
        ) || []
    );
    const serviceCategorName = Array.from(new Set(categoryList.map((el) => el)))
      ?.filter((name) => name)
      .map((item) => ({
        id: item,
        name: item,
        type: "Service Category: " + item,
        key: "12",
        active: false,
      }));
    const hostName = Array.from(new Set(data.map((el) => el.host)))
      ?.filter((name) => name)
      .map((item) => ({
        id: item,
        name: item,
        type: "Hostname: " + item,
        key: "2",
        active: false,
      }));

    const ipName = Array.from(new Set(data.map((el) => el.ip)))
      ?.filter((name) => name)
      .map((item) => ({
        id: item,
        name: item,
        type: "IP Address: " + item,
        key: "3",
        active: false,
      }));

    const hostingName = Array.from(
      new Set(data.map((el) => el.hosting_provider))
    )
      ?.filter((name) => name)
      .map((item) => ({
        id: item,
        name: item,
        type: "Hosting Provider: " + item,
        key: "5",
        active: false,
      }));
    const secutiryIssues = Array.from(
      new Set(data.map((el) => el.number_issues))
    )
      ?.filter((name) => name)
      .map((el, index) => ({
        id: index,
        name: `${el}`,
        type: "Security Issues: " + `${el}`,
        key: "9",
        active: false,
      }));
    const cdnName = Array.from(new Set(data.map((el) => el.cdn_name)))
      ?.filter((name) => name)
      .map((item) => ({
        id: item,
        name: item,
        type: "CDN: " + item,
        key: "6",
        active: false,
      }));
    const ports = Array.from(
      new Set(
        data.flatMap((host) =>
          host.scan_ports.map((scanHost) => `${scanHost.ports?.port}`)
        )
      )
    ).map((server) => ({
      id: server,
      name: server,
      type: "Port: " + server,
      key: "7",
      active: false,
    }));

    const webInterfaceName = Array.from(
      new Set(
        data.map((el) => {
          if (
            el.host_status_code === null ||
            el.host_status_code === "" ||
            el.host_status_code === undefined
          ) {
            return "No";
          } else {
            return `Yes (Status Code ${el.host_status_code})`;
          }
        })
      )
    )
      ?.filter((name) => name)
      .map((item) => ({
        id: item,
        name: item,
        type: "Web Interface: " + item,
        key: "15",
        active: false,
      }));

    setTabs(
      assetsArchiveTabsConfig({
        domainName,
        hostName,
        ipName,
        hostingName,
        cdnName,
        networkZoneFilterOptions,
        serviceCategorName,
        webInterfaceName,
        criticalityFilterOptions,
        secutiryIssues,
        discoveredByFilterOptions,
        firstDetectedFilterOptions,
      })
    );
  };
  const handleFilterChange = (updatedActiveFilters, updatedTabs) => {
    setActiveFilters(updatedActiveFilters);
    setTabs(updatedTabs);
  };
  const removeFilter = (updatedFilters, updatedTabs) => {
    setActiveFilters(updatedFilters);
    setTabs(updatedTabs);
  };
  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };
  const SecurityIssueCard = useCallback(
    (data) => {
      if (data.number_issues > 0) {
        navigate(`/security-issues/${routeParams?.id}?ip=${data?.ip}`);
      }
    },
    [navigate, routeParams?.id]
  );
  const filteredColumns = useMemo(
    () =>
      getFilteredColumns(
        allColumnsObj,
        servicesStatus,
        navigate,
        SecurityIssueCard,
        routeParams
      ),
    [allColumnsObj, servicesStatus, navigate, SecurityIssueCard, routeParams]
  );

  const fetchManageColumns = async () => {
    try {
      const { data } = await axios.get(`userSettings`);
      if (data?.columns) {
        const updatedColumns = initialColumns.map((el) => ({
          ...el,
          visibility: el.default || data.columns.includes(el.key),
        }));
        setAllColumnsObj(updatedColumns);
      } else {
        setAllColumnsObj(initialColumns);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getExportedRows = (dataForExport) => {
    const visibleKeys = allColumnsObj
      .filter((key) => key.visibility)
      .map((key) => key.key);

    const body = dataForExport.flatMap((ipData) => {
      const row = visibleKeys.map((key) => {
        switch (key) {
          case "domain":
          case "host":
          case "ip":
          case "hosting_provider":
          case "cdn_name":
            return `${ipData[key] || "-"}`;
          case "status":
            return !ipData.is_processed
              ? "Validating"
              : ipData.status
              ? "Active"
              : "Inactive";
          case "scan_ports":
            return `${
              ipData[key].length > 0
                ? ipData[key]?.map((hostData) => hostData.ports.port).join(", ")
                : "-"
            }`;
          case "issues":
            return `${ipData?.number_issues || "-"}`;
          case "discovered_by":
            return ipData[key] === 2
              ? "User’s input"
              : ipData[key] === 1
              ? "Armory"
              : "-";
          case "first_detected":
            return `${
              formatDate(ipData?.first_detected) || "-"
            }`;
          case "archive_date":
            return `${ formatDate(ipData?.archive_date) || "-"}`;
          case "current_state":
            return `${
              ipData?.is_rejected
                ? "Rejected"
                : ipData?.is_removed
                ? "Removed"
                : "-"
            }`;
          case "asset_criticality":
            return `${
              ipData?.criticality === 1
                ? "Low"
                : ipData?.criticality === 2
                ? "Medium"
                : ipData?.criticality === 3
                ? "High"
                : "-"
            }`;
          case "service_category":
            return `${
              ipData.scan_ip_categories?.length > 0
                ? ipData.scan_ip_categories
                    .map((category) => category.service_category.title)
                    .join(", ")
                : "-"
            }`;
          case "network_zone":
            return `${
              ipData.network_zone === 1
                ? "On-Premise"
                : ipData.network_zone === 2
                ? "Cloud-Hosted"
                : "-"
            }`;
          default:
            return ipData[key] || "-";
        }
      });
      return [row];
    });

    return body;
  };

  const groupedFilters = activeFilters.reduce((acc, filter) => {
    if (!acc[filter.eventKey]) {
      acc[filter.eventKey] = [];
    }
    acc[filter.eventKey].push(filter);
    return acc;
  }, {});

  const handleChartClick = (name) => {
    setActiveFilters(webUpdatesFilter(name, activeFilters, "8"));
  };

  const filteredData = data.filter((target) => {
    const matchesFilters = Object.keys(groupedFilters).every((eventKey) => {
      return groupedFilters[eventKey].some((filter) => {
        const lowerCaseFilter = filter.name.toLowerCase();
        if (eventKey === "1") {
          return target.domain.toLowerCase() === lowerCaseFilter;
        } else if (eventKey === "3") {
          return target.ip && target.ip === lowerCaseFilter;
        } else if (eventKey === "2") {
          return target.host.toLowerCase() === lowerCaseFilter;
        } else if (eventKey === "5") {
          return (
            target.hosting_provider &&
            target.hosting_provider.toLowerCase() === lowerCaseFilter
          );
        } else if (eventKey === "4") {
          return (
            (lowerCaseFilter === "validating" && !target.is_processed) ||
            (lowerCaseFilter === "active" &&
              target.is_processed &&
              target.status === 1) ||
            (lowerCaseFilter === "inactive" &&
              target.is_processed &&
              !target.status)
          );
        } else if (eventKey === "6") {
          return (
            target.cdn_name && target.cdn_name.toLowerCase() === lowerCaseFilter
          );
        } else if (eventKey === "7") {
          return (
            target.scan_ports &&
            target.scan_ports.some(
              (port) =>
                port?.ports.port &&
                `${port.ports.port}`.toLowerCase() === lowerCaseFilter
            )
          );
        } else if (eventKey === "8") {
          const filterName = filter.name.toLowerCase();
          if (filterName === "rejected") {
            return target?.is_rejected === true;
          } else if (filterName === "removed") {
            return target?.is_removed === true;
          }
        } else if (eventKey === "9") {
          return Number(target?.number_issues) == Number(filter.name);
        } else if (eventKey === "10") {
          return (
            (filter.name === "User’s input" && target.discovered_by === 2) ||
            (filter.name === "Armory" && target.discovered_by === 1)
          );
        } else if (eventKey === "11") {
          return (
            (filter.name === "On-Premise" && target.network_zone === 1) ||
            (filter.name === "Cloud-Hosted" && target.network_zone === 2)
          );
        } else if (eventKey === "13") {
          return (
            (filter.name === "Low" && target.criticality === 1) ||
            (filter.name === "Medium" && target.criticality === 2) ||
            (filter.name === "High" && target.criticality === 3)
          );
        } else if (eventKey === "12") {
          return (
            target.scan_ip_categories &&
            target.scan_ip_categories.some(
              (port) =>
                port?.service_category.title &&
                `${port.service_category.title}`.toLowerCase() ===
                  lowerCaseFilter
            )
          );
        } else if (eventKey === "15") {
          if (filter.name?.toLowerCase() == "no") {
            return (
              target.host_status_code === null ||
              target.host_status_code === "" ||
              target.host_status_code === undefined
            );
          } else {
            return filter.name?.includes(target.host_status_code);
          }
        } else if (eventKey === "14") {
          return applyCondition(
            target.first_detected,
            filter.name.toLowerCase().replaceAll(" ", "_"),
            filter.name
          );
        } else if (eventKey === "advanced-filter") {
          const parsedFilters = parseFilterString(filter.name);
          return parsedFilters.every((ol) => {
            const { column, condition, value } = ol;
            switch (column) {
              case "domain":
                return applyCondition(
                  target.domain?.toLowerCase(),
                  condition,
                  value?.toLowerCase()
                );
              case "host":
                return applyCondition(
                  target.host?.toLowerCase(),
                  condition,
                  value?.toLowerCase()
                );
              case "ip":
                return applyCondition(
                  target.ip?.toLowerCase().toLowerCase(),
                  condition,
                  value?.toLowerCase()
                );
              case "status":
                return (
                  (value?.toLowerCase() === "validating" &&
                    !target.is_processed) ||
                  (value?.toLowerCase() === "active" &&
                    target.is_processed &&
                    target.status === 1) ||
                  (value?.toLowerCase() === "inactive" &&
                    target.is_processed &&
                    !target.status)
                );
              case "hosting_provider_discovery":
                return applyCondition(
                  target.hosting_provider?.toLowerCase(),
                  condition,
                  value?.toLowerCase()
                );
              case "cdn_name_discovery":
                return applyCondition(
                  target.cdn_name?.toLowerCase(),
                  condition,
                  value?.toLowerCase()
                );
              case "number_issues":
                return applyCondition(
                  `${target?.number_issues}`,
                  condition,
                  value
                );
              case "host_status_code":
                if (value?.toLowerCase() == "no") {
                  const isStatusCode =
                    target.host_status_code === null ||
                    target.host_status_code === "" ||
                    target.host_status_code === undefined;
                  return condition == "is_not" ? !isStatusCode : isStatusCode;
                } else {
                  return condition == "is_not"
                    ? !value?.includes(target.host_status_code)
                    : value?.includes(target.host_status_code);
                }
              case "first_detected":
                return applyCondition(target.first_detected, condition, value);
              case "port":
                return (
                  target.scan_ports &&
                  target.scan_ports.some((port) =>
                    applyCondition(
                      `${port.ports.port}`.toLowerCase(),
                      condition,
                      value?.toLowerCase()
                    )
                  )
                );
              case "discovered_by":
                return (
                  (value === "User’s input" && target.discovered_by === 2) ||
                  (value === "Armory" && target.discovered_by === 1)
                );
              case "network_zone":
                return (
                  (value === "On-Premise" && target.network_zone === 1) ||
                  (value === "Cloud-Hosted" && target.network_zone === 2)
                );
              case "service_category":
                return (
                  target.scan_ip_categories &&
                  target.scan_ip_categories.some(
                    (port) =>
                      port?.service_category.title &&
                      `${port.service_category.title}`.toLowerCase() ===
                        value?.toLowerCase()
                  )
                );
              case "criticality":
                return (
                  (value === "Low" && target.criticality === 1) ||
                  (value === "Medium" && target.criticality === 2) ||
                  (value === "High" && target.criticality === 3)
                );
              default:
                return false;
            }
          });
        }
        return false;
      });
    });

    const matchesSearch =
      searchValue === "" ||
      target.domain?.toLowerCase().includes(searchValue?.toLowerCase()) ||
      target.host?.toLowerCase().includes(searchValue?.toLowerCase()) ||
      target.hosting_provider
        ?.toLowerCase()
        .includes(searchValue?.toLowerCase()) ||
      target.cdn?.toLowerCase().includes(searchValue?.toLowerCase()) ||
      (target.ip &&
        typeof target.ip === "string" &&
        target.ip?.toLowerCase().includes(searchValue?.toLowerCase()));

    return matchesFilters && matchesSearch;
  });

  useEffect(() => {
    if (data?.length > 0) {
      updateTabsWithData(data);
    }
  }, [data]);

  useEffect(() => {
    const targetScan = dropdownItems.filter((item) => {
      return item.target_uuid === routeParams?.id;
    })[0];
    setTargetScan(targetScan);
  }, [dropdownItems]);

  useEffect(() => {
    if (assetsStatus) {
      setDoughnutChartType({
        labels: ["Rejected", "Removed"],
        datasets: [
          {
            data: [assetsStatus?.rejected || 0, assetsStatus?.removed || 0],
            backgroundColor: colorList,
            hoverBackgroundColor: colorList,
            borderColor: "transparent",
            borderWidth: 0,
          },
        ],
      });
    }
  }, [assetsStatus]);
  useEffect(() => {
    fetchManageColumns();
  }, []);

  const updateColumnSettings = async () => {
    const columns = allColumnsObj
      .filter((el) => el.visibility)
      .map((el) => el.key)
      .join(",");

    try {
      await axios.post(`updateUserSettings`, {
        table_name: "ASSETS_ARCHIVE",
        columns,
      });
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    if (allColumnsObj?.length > 0) {
      updateColumnSettings();
    }
  }, [allColumnsObj]);

  return (
    <>
      {data.length !== 0 ? (
        <>
          <div className="row mb-4 ">
            <div className="col-6 h-50">
              <GenericCard
                children={
                  <Data
                    items={[
                      {
                        icon: GlobeIcon,
                        label: "Domains",
                        value: summary?.domains || 0,
                        active: null,
                      },
                      {
                        icon: IPAddressIcon,
                        label: "IP Addresses",
                        value: summary?.ips || 0,
                        active: null,
                      },
                      {
                        icon: HostnameIcon,
                        label: "Hostnames",
                        value: summary?.hosts || 0,
                        active: null,
                      },
                    ]}
                  />
                }
                title={"Summary"}
              />
            </div>

            <div className="col-6 chart-with-position">
              <GenericCard
                children={
                  <DoughnutChart
                    data={doughnutChartType}
                    options={doughnutChartOptions}
                    plugins={[ChartDataLabels]}
                    height={104}
                    width={209}
                    onHandleClick={handleChartClick}
                  />
                }
                title={"Asset State"}
              />
            </div>
          </div>
          <div>
            <BaseFilter
              tabs={tabs}
              className="mt-4"
              onFilterChange={handleFilterChange}
              activeFilters={activeFilters}
              removeFilter={removeFilter}
              iskeyfilter={true}
              searchValue={searchValue}
              onSearchChange={handleSearchChange}
              isSearch={true}
              isManageColumn={true}
              allColumns={allColumnsObj}
              setAllColumns={setAllColumnsObj}
              totalRecords={filteredData?.length}
              exportTitle={`AssetsDiscovery_${formatScannerName(
                targetScan?.title
              )}-${moment().format("DDMMMYYYY").toUpperCase()}`}
              exportRows={getExportedRows(filteredData)}
              exportHeader={allColumnsObj
                .filter((col) => col.visibility)
                .map((col) => col.header)}
            />
          </div>
          {filteredColumns.length > 0 ? (
            <div>
              <div className="assets-discovery-table  custom-x-scroll-table">
                <BaseTable
                  className="mt-3 mb-3"
                  columns={filteredColumns}
                  data={filteredData}
                  selectable={false}
                  showCheckboxes={false}
                  action={false}
                  loading={false}
                  isDatainProcess={false}
                  stickyAction={true}
                  isStickyScroll
                />
              </div>
            </div>
          ) : null}
        </>
      ) : (
        <div className="empty-state-container">
          <EmptyAssetsArchive />
        </div>
      )}
    </>
  );
};

export default AssetsArchive;
