import { ReactComponent as ExternalLinkIcon } from "../../../../assets/images/external-link-icon.svg";
import { UseAssetsModal } from "../../../../hooks/useAssetsModal.js";

const HostnamesList = ({ hostname = "", ip = {} }) => {
  const { openModal } = UseAssetsModal();

  const handleHostnameClick = (hostname) => {
    openModal(hostname);
  };

  return (
    <div>
      {hostname.length > 0 && (
        <div className="mb-2 hostname-link">
          <span
            className=""
            style={{ cursor: "pointer" }}
            onClick={() => handleHostnameClick(hostname)}
          >
            <span className="asset-link">{hostname}</span>
          </span>
          <a
            href={`http://${hostname}`}
            target="_blank"
            rel="noopener noreferrer"
            className=" ms-1"
            style={{ textDecoration: "none" }}
          >
            <ExternalLinkIcon />
          </a>
        </div>
      )}

      {ip.ip && (
        <div className="mb-2 hostname-link">
          <span
            className=""
            style={{ cursor: "pointer" }}
            onClick={() => handleHostnameClick(ip.ip)}
          >
            <span className="asset-link">
              {ip.ip}:{ip.port}
            </span>
          </span>
          <a
            href={`https://${ip.ip}:${ip.port}`}
            target="_blank"
            rel="noopener noreferrer"
            className=" ms-1"
            style={{ textDecoration: "none" }}
          >
            <ExternalLinkIcon />
          </a>
        </div>
      )}
    </div>
  );
};

export default HostnamesList;
