import React, { useState } from "react";

const CustomTagSelector = ({ field, form, options, disabled }) => {
  const [showMenu, setShowMenu] = useState(false);

  const handleSelect = (option) => {
    const selectedTags = field.value || [];
    if (selectedTags.includes(option.value)) {
      form.setFieldValue(
        field.name,
        selectedTags.filter((tag) => tag !== option.value)
      );
    } else {
      form.setFieldValue(field.name, [...selectedTags, option.value]);
    }
    setShowMenu(false);
  };

  const selectedTags = field.value || [];

  return (
    <div className="tag-selector-container">
      <div
        onClick={() => setShowMenu(!showMenu)}
        className={`selected-tags custom-select ${disabled && 'selected-tags-disabled'}`}
      >
        {selectedTags.map((tag, index) => {
          const selectedOption = options.find((option) => option.value === tag);
          return (
            <div
              key={index}
              className="tested-tag-badge"
              style={{
                color: selectedOption?.color,
                border: `1px solid ${selectedOption?.color}`,
              }}
            >
              {selectedOption?.icon}
              {selectedOption?.label}
            </div>
          );
        })}
        {(!selectedTags || selectedTags?.length == 0) && (
          <div className="unselected-badge">Select Option</div>
        )}
      </div>

      {showMenu && (
        <div className="tag-menu">
          {options.map((option, index) => (
            <div
              key={index}
              className="tag-option"
              onClick={() => handleSelect(option)}
              onMouseEnter={(e) =>
                (e.currentTarget.style.backgroundColor = "#444")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.backgroundColor = "#333")
              }
            >
              <div
                className="tested-tag-badge"
                style={{
                  color: option?.color,
                  border: `1px solid ${option?.color}`,
                }}
              >
                {option.icon}
                {option.label}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomTagSelector;
