import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import WebScanner from "../../../../components/web-scanner/WebScanner";
import WebUpdate from "../../../../components/website-updates/WebUpdate";
import GenericCard from "../../../../components/card/GenericCard";
import { NoDataAfterScanning } from "../../../../components/empty/NoDataAfterScanning";
import NoData from "../../../../components/empty/NoData";
import BarChart from "../../../../components/charts/BarChart";
import BaseTable from "../../../../components/table/BaseTable";
import BaseFilter from "../../../../components/filter/BaseFilter";
import { useParams, useOutletContext } from "react-router-dom";
import { ScannerContext } from "../../../../components/ScannerContext";
import { ThreeCircles } from "react-loader-spinner";
import CurrentStatus from "../../../../components/status/CurrentStatus.js";
import moment from "moment";
import { formatScannerName } from "../../../../helpers/formatScannerName.js";
import HorizontalBarChart from "../../../../components/charts/HorizontalBarChart";
import DecisionToggle from "../../../../components/badge/DecisionToggle";
import axios from "../../../../util/axios";
import {
  webUpdatesFilter,
  webDataFillter,
} from "../../../../util/genericFunctions.js";
import {
  httpsFilterOptions,
  httpFilterOptions,
  statusMap,
  exportHeaders,
} from "./filterOptions.js";
import { ReactComponent as ExternalLinkIcon } from "../../../../assets/images/external-link-icon.svg";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { parseFilterString, applyCondition } from "../../../../util/conditions";
import { loginPagesSecurityTabsConfig } from "../../../../util/tabsConfig.js";
import  { formatDate } from "../../../../util/formatDate.js";
import { UseAssetsModal } from "../../../../hooks/useAssetsModal.js";

const LoginPagesSecurity = () => {
  const { openModal } = UseAssetsModal();
  const { dropdownItems } = useContext(ScannerContext);
  const { scanningStatus } = useOutletContext();
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [horizontalBarChartType, setHorizontalBarChartType] = useState([]);
  const [isDatainProcess, setIsDataInProcess] = useState(false);
  const [isDataisPartiallyProcessing, setIsDataisPartiallyProcessing] =
    useState(false);
  const [noDataDoughnutChart, setNoDataDoughtnutChart] = useState(false);
  const [noDataBarChart, setNoDataBarChart] = useState(false);
  const [barChartType, setBarChartType] = useState([]);
  const [activeFilters, setActiveFilters] = useState([]);
  const routeParams = useParams();
  const [pages, setPages] = useState([]);
  const [webUpdatesData, setWebUpdatesData] = useState(null);
  const [tabs, setTabs] = useState([]);
  const [barChartData, setBarChartData] = useState({
    labels: ["Secured", "Unsecured"],
    datasets: [
      {
        label: "",
        data: [],
        backgroundColor: ["#3DDC97", "#FF6155"],
        borderColor: ["rgb(75, 192, 192)", "rgb(255, 99, 132)"],
        borderWidth: 1,
      },
    ],
  });

  const columns = [
    {
      Header: "Hostname",
      key: "host",
      accessor: (row) => {
        return (
          <span onClick={() => openModal(row?.host)} className="asset-link">
            {row?.host}
          </span>
        );
      },
      isSortable: true,
    },
    {
      Header: "Login Page",
      isSortable: true,
      key: "login_page",
      accessor: (row) => (
        <>
          <OverlayTrigger
            placement="top"
            overlay={
              <Popover
                id={`tooltip-${row.id}`}
                className="custom-popover-arrow"
              >
                <Popover.Body className="comming-tool-body">
                  <label className="comming-text-tool">{row?.login_page}</label>
                </Popover.Body>
              </Popover>
            }
          >
            <a
              href={`http://${row?.login_page}`}
              target="_blank"
              rel="noopener noreferrer"
              className="hostname-link"
              style={{ textDecoration: "none", wordWrap: "break-word" }}
            >
              <span className="text-login-page-ellipsis">
                {row?.login_page}
              </span>{" "}
              <ExternalLinkIcon className="ms-1" />
            </a>
          </OverlayTrigger>
        </>
      ),
    },
    {
      Header: "Login Over HTTPS",
      accessor: "https",
      isSortable: true,
      accessor: (row) => (
        <div className="d-flex align-items-center">
          <DecisionToggle
            label={row?.https ? "Yes" : "No"}
            isIndication={row?.https ? true : false}
          />
        </div>
      ),
    },
    {
      Header: "Login Over HTTP",
      accessor: "http",
      isSortable: true,
      accessor: (row) => (
        <div className="d-flex align-items-center">
          <DecisionToggle
            label={row?.http ? "Yes" : "No"}
            isIndication={row?.http ? false : true}
          />
        </div>
      ),
    },

    {
      Header: "First Detected",
      key: "first_detected",
      isSortable: true,
      accessor: (row) => (
        <div>
          {row?.first_detected
            ? formatDate(row?.first_detected)
            : ""}
        </div>
      ),
    },
    {
      Header: "Current State",
      key: "current_state",
      isSortable: true,
      accessor: (row) => {
        return (
          <div>
            {row?.change_status != null ? (
              <CurrentStatus
                status={row?.change_status}
                tooltipInfo={row?.data_changes}
                headerKeys={{
                  host: "Hostname",
                  login_page: "Login Page",
                  https: "Login Over HTTPS",
                  http: "Login Over HTTP",
                  first_detected: "First Detected",
                  change_status: "Current State",
                }}
              />
            ) : (
              ""
            )}
          </div>
        );
      },
    },
  ];
  const animationDuration = 750;
  const barChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: animationDuration,
      easing: "easeOutQuart",
    },
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          color: "white",
          padding: 30,
          fillStyle: "none",
          usePointStyle: true,
          pointStyle: "rectRounded",
          boxSize: 0,
          boxWidth: 0,
          font: {
            family: "Kodchasan",
          },
        },
      },
      tooltip: {
        backgroundColor: "white",
        titleColor: "black",
        bodyColor: "black",
        padding: 20,
        yPadding: 90,
        callbacks: {
          title: () => {
            return "";
          },
          label: (context) => {
            const label = context.dataset.label || "";
            const value = context.raw;
            return `${label}: ${value}`;
          },
        },
        yAlign: "bottom",
        xAlign: "center",
        titleFont: {
          family: "Kodchasan",
        },
        bodyFont: {
          family: "Kodchasan",
        },
      },
      title: {
        display: false,
        text: "Most Used Ports",
        color: "white",
        font: {
          family: "Kodchasan",
          color: "white",
          size: 24,
        },
      },
      datalabels: {
        color: "white",
        anchor: "end",
        align: "top",
        formatter: (value, context) => {
          return value;
        },
        font: {
          color: "white",
          weight: "bold",
          family: "Kodchasan",
          size: 12,
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          color: "white",
          precision: 0,
          font: {
            color: "white",
            family: "Kodchasan",
          },
        },
        border: { dash: [4, 4], borderColor: "black" },
        grid: {
          color: "rgba(255, 255, 255, 0.1)",
          borderDash: [10, 10],
        },
      },
      x: {
        ticks: {
          color: "white",
          font: {
            family: "Kodchasan",
          },
        },
        display: true,
        grid: {
          color: "transparent",
          drawOnChartArea: true,
          borderDash: [10, 10],
          borderDashOffset: function (context) {
            return context.index === context.chart.data.labels.length ? 0 : 10;
          },
        },
        border: {
          color: "#959595",
          width: 1,
        },
      },
    },
    layout: {
      padding: {
        top: 30,
        bottom: -40,
      },
    },
  };

  const horizontalBarChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: "y",
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    animation: {
      duration: animationDuration,
      easing: "easeOutQuart",
    },
    plugins: {
      legend: {
        display: false,
        position: "bottom",
        labels: {
          color: "white",
        },
        font: {
          family: "Kodchasan",
        },
      },
      tooltip: {
        backgroundColor: "white",
        titleColor: "black",
        bodyColor: "black",
        padding: 20,
        yPadding: 90,
        callbacks: {
          title: () => "",
          label: (context) => {
            const label = context.dataset.label || "";
            const value = context.raw;
            return `${label}: ${value}`;
          },
        },

        yAlign: "bottom",
        xAlign: "center",
        titleFont: {
          family: "Kodchasan",
        },
        bodyFont: {
          family: "Kodchasan",
        },
      },
      title: {
        display: false,
        text: "Most Used Ports",
        color: "white",
        font: {
          size: 24,
        },
      },
      datalabels: {
        anchor: "end",
        align: "end",
        formatter: Math.round,
        font: {
          weight: "bold",
          family: "Kodchasan",
          size: 12,
        },
        color: "white",
        padding: {
          right: 10,
        },
        clip: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          color: "white",
          font: {
            family: "Kodchasan",
            weight: 400,
            size: 10,
          },
        },
        border: { dash: [4, 4] },

        grid: {
          color: "rgba(255, 255, 255, 0)",
        },
      },
      x: {
        ticks: {
          precision: 0,
          color: "white",
        },
        border: { dash: [4, 4] },

        grid: {
          color: "rgba(255, 255, 255, 0.1)",
        },
        beginAtZero: true,
      },
    },
    layout: {
      padding: {
        right: 20,
      },
    },
  };

  const updateTabsWithData = (loginData) => {
    let hostnameCounter = 1;
    let loginPageCounter = 1;
    const hostnames = Array.from(
      new Set(loginData.map((login) => login.host))
    ).map((host) => ({
      id: `host-${hostnameCounter++}`,
      name: host ? host : "-",
      type: host ? "Hostname: " + host : "Hostname: -",
      key: "1",
      active: false,
    }));

    const loginPages = Array.from(
      new Set(loginData.map((login) => login.login_page))
    ).map((login_page) => ({
      id: `login-${loginPageCounter++}`,
      name: login_page ? login_page : "-",
      type: login_page ? "Login Page: " + login_page : "Login Page: -",
      key: "2",
      active: false,
    }));
    setTabs(
      loginPagesSecurityTabsConfig({
        hostnames,
        loginPages,
        httpsFilterOptions,
        httpFilterOptions,
      })
    );
  };

  const handleFilterChange = (updatedActiveFilters, updatedTabs) => {
    setActiveFilters(updatedActiveFilters);
    setTabs(updatedTabs);
  };

  const removeFilter = (updatedFilters, updatedTabs) => {
    setActiveFilters(updatedFilters);
    setTabs(updatedTabs);
  };
  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const horizontalBarChartData = useMemo(() => {
    const { http = 0, both = 0, https = 0 } = horizontalBarChartType;

    return {
      labels: [
        "Login Over HTTPS",
        "Login Over Both HTTP and HTTPS",
        "Login Over HTTP",
      ],
      datasets: [
        {
          label: "Certificate",
          data: [https, both, http],
          backgroundColor: ["#3DDC97", "#FF6155", "#FF6155"],
          hoverOffset: 4,
          borderColor: "transparent",
          borderWidth: 0,
          width: 50,
          borderAlign: "center",
        },
      ],
    };
  }, [horizontalBarChartType]);

  const groupedFilters = activeFilters.reduce((acc, filter) => {
    if (!acc[filter.eventKey]) {
      acc[filter.eventKey] = [];
    }
    acc[filter.eventKey].push(filter);
    return acc;
  }, {});

  const filteredLogins = pages.filter((login) => {
    const matchesFilters = Object.keys(groupedFilters).every((eventKey) => {
      return groupedFilters[eventKey].some((filter) => {
        const lowerCaseFilter = filter.name.toLowerCase();
        if (eventKey === "1") {
          return login.host.toLowerCase() === lowerCaseFilter;
        } else if (eventKey === "2") {
          return login.login_page.toLowerCase() === lowerCaseFilter;
        } else if (eventKey === "3") {
          return (
            (lowerCaseFilter === "yes" && login.https) ||
            (lowerCaseFilter === "no" && !login.https)
          );
        } else if (eventKey === "7") {
          return login?.change_status === statusMap[filter.name.toLowerCase()];
        } else if (eventKey === "4") {
          return (
            (lowerCaseFilter === "yes" && login.http) ||
            (lowerCaseFilter === "no" && !login.http)
          );
        } else if (eventKey === "advanced-filter") {
          const parsedFilters = parseFilterString(filter.name);
          return parsedFilters.every((ol) => {
            const { column, condition, value } = ol;
            switch (column) {
              case "host":
                return applyCondition(
                  login.host?.toLowerCase(),
                  condition,
                  value?.toLowerCase()
                );
              case "login_page":
                return applyCondition(
                  login.login_page.toLowerCase(),
                  condition,
                  value?.toLowerCase()
                );
              case "https":
                return (
                  (value?.toLowerCase() === "no" && !login.https) ||
                  (value?.toLowerCase() === "yes" && login.https)
                );
              case "http":
                return (
                  (value?.toLowerCase() === "no" && !login.http) ||
                  (value?.toLowerCase() === "yes" && login.http)
                );
              case "current_state":
                return applyCondition(
                  login?.change_status,
                  condition,
                  statusMap[value.toLowerCase()]
                );

              default:
                return false;
            }
          });
        }
        return false;
      });
    });

    const matchesSearch =
      searchValue === "" ||
      (login.host &&
        login.host.toLowerCase().includes(searchValue.toLowerCase())) ||
      (login.login_page &&
        login.login_page.toLowerCase().includes(searchValue.toLowerCase()));

    return matchesFilters && matchesSearch;
  });
  const fetchLoginPages = async () => {
    try {
      const { data } = await axios.get(
        `scans/${routeParams.target_id}/scan-login-pages`
      );
      if (data.loginPages?.length > 0) {
        setPages(data.loginPages);
        updateTabsWithData(data.loginPages);
      }
      if (data?.groups) {
        setWebUpdatesData(data?.groups?.changes);
        setBarChartData({
          labels: ["Unsecured", "Secured"],
          datasets: [
            {
              label: "",
              data: [
                data?.groups?.loginPagesSecurity?.unsecure || 0,
                data?.groups?.loginPagesSecurity?.secure || 0,
              ],
              backgroundColor: ["#FF6155", "#3DDC97"],
              borderColor: ["rgb(255, 99, 132)", "rgb(75, 192, 192)"],
              borderWidth: 1,
            },
          ],
        });
        setHorizontalBarChartType({
          http: data.groups.loginPages.http,
          both: data.groups.loginPages.both,
          https: data.groups.loginPages.https,
        });
        if (
          data?.groups?.loginPagesSecurity?.unsecure ||
          data?.groups?.loginPagesSecurity?.secure
        ) {
          setNoDataBarChart(false);
        } else {
          setNoDataBarChart(true);
        }
      }
      if (
        data?.loginPages?.length == 0 &&
        (scanningStatus === 2 || scanningStatus === 1)
      ) {
        setIsDataInProcess(true);
      } else {
        setIsDataInProcess(false);
      }

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const fetchIntervalRef = useRef();
  useEffect(() => {
    clearInterval(fetchIntervalRef.current);
    if (scanningStatus === 0) {
      setIsLoading(false);
      setIsDataInProcess(true);
    } else {
      setIsDataInProcess(false);
      if (scanningStatus === 3 || scanningStatus === -1) {
        setIsDataisPartiallyProcessing(false);
        setIsLoading(true);
        fetchLoginPages();
      } else if (scanningStatus === 2 || scanningStatus === 1) {
        setIsDataisPartiallyProcessing(true);
        setIsLoading(true);
        fetchLoginPages();
        fetchIntervalRef.current = setInterval(fetchLoginPages, 5000);
      }
    }
    return () => {
      clearInterval(fetchIntervalRef.current);
    };
  }, [scanningStatus, routeParams?.target_id]);

  const exportRows = useMemo(() => {
    return filteredLogins.map((login) => [
      login.host,
      login.login_page,
      login.https ? "Yes" : "No",
      login.http ? "Yes" : "No",
    ]);
  }, [filteredLogins]);

  const handleWebUpdateSort = (name) => {
    setActiveFilters(webUpdatesFilter(name, activeFilters, "7"));
  };

  return (
    <>
      {isLoading ? (
        <div className="content-loader">
          <ThreeCircles
            visible={true}
            height="60"
            width="60"
            color="#ffff"
            ariaLabel="three-circles-loading"
            wrapperClass=""
          />
        </div>
      ) : (
        <div className="main_container_style">
          <div className="pb-4 row waf-main web-updates-container">
            <div className="col-12  col-lg-6 h-100 web-updates-col-7">
              <div className="row gap-4 waf-row">
                <div className="col-12 h-50">
                  <GenericCard
                    title={"HTTP vs HTTPS Login Pages"}
                    children={
                      isDatainProcess ? (
                        <NoData />
                      ) : isDataisPartiallyProcessing ? (
                        <NoData />
                      ) : horizontalBarChartType.http ||
                        horizontalBarChartType.both ||
                        horizontalBarChartType.https ? (
                        <HorizontalBarChart
                          HorizontalBarChartdata={horizontalBarChartData}
                          HorizontalBarChartoptions={horizontalBarChartOptions}
                          isChartLabelsOnTop={true}
                        />
                      ) : (
                        <NoDataAfterScanning />
                      )
                    }
                  />
                </div>
                <div className="col-12 h-50">
                  <div className="row h-100">
                    <div className="col-5">
                      <GenericCard
                        children={
                          <WebUpdate
                            data={webUpdatesData}
                            onSort={handleWebUpdateSort}
                            dataInProcess={
                              isDatainProcess || isDataisPartiallyProcessing
                            }
                          />
                        }
                        title={"Updates"}
                      />
                    </div>
                    <div className="col-7 h-100">
                      <GenericCard
                        children={
                          isDatainProcess ? (
                            <NoData />
                          ) : isDataisPartiallyProcessing && noDataBarChart ? (
                            <NoData />
                          ) : noDataBarChart ? (
                            <NoDataAfterScanning />
                          ) : (
                            <BarChart
                              isChartLabelsOnTop
                              barData={barChartData}
                              optionsData={barChartOptions}
                              isData={
                                !!(
                                  barChartType &&
                                  barChartType.no !== undefined &&
                                  barChartType.yes !== undefined
                                )
                              }
                            />
                          )
                        }
                        title={"Login Pages Security"}
                        subtitle={"View Issues"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 h-100 web-updates-col-5">
              <WebScanner title={"Login Pages Security Updates"} />
            </div>
          </div>

          <div className=" pb-5">
            <div>
              <BaseFilter
                tabs={tabs}
                onFilterChange={handleFilterChange}
                activeFilters={activeFilters}
                removeFilter={removeFilter}
                className="mb-3"
                totalRecords={filteredLogins.length}
                exportHeader={exportHeaders}
                exportRows={exportRows}
                exportTitle={`LoginPagesSecurity_${formatScannerName(
                  dropdownItems.filter((item) => {
                    return item.target_uuid === routeParams?.target_id;
                  })[0]?.title
                )}-${moment().format("DDMMMYYYY").toUpperCase()}`}
                isDatainProcess={isDatainProcess || isDataisPartiallyProcessing}
                searchValue={searchValue}
                onSearchChange={handleSearchChange}
                isSearch={true}
                tableData={filteredLogins}
              />
            </div>
            <div>
              <BaseTable
                className="mt-3 mb-3"
                columns={columns}
                data={filteredLogins}
                selectable={true}
                showCheckboxes={false}
                action={false}
                isDatainProcess={isDatainProcess || isDataisPartiallyProcessing}
                loading={isLoading}
                isStickyScroll
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LoginPagesSecurity;
