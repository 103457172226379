import React from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import core from '../../util/core';
import Moment from 'react-moment';
import { ReactComponent as IconPhone } from '../../assets/images/icon-phone.svg';
import { ReactComponent as IconMail } from '../../assets/images/icon-mail.svg';
import { ReactComponent as IconMessage } from '../../assets/images/icon-message.svg';
import { Link } from 'react-router-dom';
import  { formatDate } from "../../util/formatDate";


const SpoofSingle = ({ item }) => {
    let typeIcon;
    if ('phone' == item.type) {
        typeIcon = <IconPhone width="20" className="me-1" />;
    }
    else if ('sms' == item.type) {
        typeIcon = <IconMessage width="20" className="me-1" />;
    }
    else {
        typeIcon = <IconMail width="22" className="me-2" />;
    }

    return (
        <Link to={{ pathname: '/spoof/' + item.type, search: `?id=${item.id}` }} className='nav-link'>
            <Row className=' rounded border m-0 my-2  shadow-sm' >

                <Col className='d-flex flex-column justify-content-between p-2'>
                    <div className='m-1'>{typeIcon}  <span className="text-muted text-capitalize">{item.type}</span></div>

                    <div className='mt-2 m-1'>
                        <div className='mb-2'  >
                            <i className="bi bi-telephone-outbound-fill me-2" title='From'></i>{"From: "}
                            <span className="ms-1 text-muted">
                                {core.phoneMask(item.from)}
                            </span>
                        </div>
                        <div className='mb-2'>
                            <i className="bi bi-telephone-inbound-fill me-2" title='To'></i>{"To: "}
                            <span className="ms-1 text-muted">
                                {core.phoneMask(item.to)}
                            </span>
                        </div>
                        <div className='mb-2'>
                            <i className="bi bi-clock me-2" title='Date & Time'></i>Date & Time {": "}
                            <small className="ms-1 text-muted">
                            {formatDate(item.created_at, "MMM DD, YYYY hh:mm")}
                            </small>
                        </div>

                    </div>
                </Col>
            </Row>
        </Link>

    )
};

export default SpoofSingle;