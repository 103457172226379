import React from "react";
import { ReactComponent as Export } from "../../assets/images/Export.svg";
import request from "../../util/fetch";
import { formatDate } from "../../util/formatDate.js";

const CyberServicesCard = ({ data }) => {
  const { title, objective, submit_date, doc } = data;
  const renderObjective = () => {
    if (objective.includes("https")) {
      return (
        <a
          href={objective}
          target="_blank"
          rel="noopener noreferrer"
          className="cursor-pointer "
        >
          {objective}
        </a>
      );
    } else {
      return <span className="fw-bold">{objective}</span>;
    }
  };
  const downloadFile = () => {
    request("/cyber-services/download/" + data.id)
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = doc;
        link.click();
      })
      .catch((error) => console.error(error));
  };

  return (
    <React.Fragment>
      <div className="cyberservicesCard_Main">
        <div className="cyberservicesCard_submain">
          <div className="cyberservice_container">
            <div className="cyberservice_title">{title}</div>
            <div className="row">
              <div className="col-4 cyber_container_col">
                <div className="mt-3">
                  <div className="cyberservice_header_title">Objective</div>
                  <div className="cyberservice_header_text">
                    {renderObjective()}
                  </div>
                </div>
              </div>
              <div className="col-2 cyber_container_col">
                <div className="mt-3">
                  <div className="cyberservice_header_title">Date</div>
                  <div className="cyberservice_header_text fw-bold">
                    {" "}
                    {submit_date ? formatDate(submit_date) : ""}
                  </div>
                </div>
              </div>
              <div className="col-4 cyber_container_col">
                <div className="mt-3">
                  <div className="cyberservice_header_title">Document</div>
                  <div className="cyberservice_header_text fw-bold  d-flex">
                    <span>{doc}</span>
                    <span className="table-export cursor-pointer fs-6 ms-3">
                      {doc ? (
                        <p
                          onClick={downloadFile}
                          className="table-export cursor-pointer fs-6 ms-2"
                        >
                          <Export
                            className="export-icon"
                            height={25}
                            width={25}
                          />
                          Download
                        </p>
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CyberServicesCard;
